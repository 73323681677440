<template>
  <div>
    <Page class="mb-5" :sections="sections" />
  </div>
</template>

<script lang="babel" type="text/babel">
import detailMixins from "@/components/page/detail.js"
import Page from '@/components/dynamic/page.vue'
export default {
  mixins: [detailMixins],
  components: {
    Page
  },
  data: () => ({

  }),
  computed: {
    api() {
      if(this.hasPreviewLink) return this.$api.home.admin.preview
      return this.$api.home.public.read
    },
    sections() {
      if(!this.pageData) return []
      if(!this.pageData.meta) return []
      return this.pageData.meta.sections
    },
    mainApiLoadDone() {
      return this.$store.getters['base/mainApiLoadDone']
    },
  },
  watch: {
    async mainApiLoadDone() {
      if(!this.mainApiLoadDone) return
      await this.$helper.delay(0.5)
      // 抓取第一個banner的高, 當作logo變色的基準點
      if(!$('.ecvBanner')[0]) return
      const height = $('.ecvBanner')[0].offsetHeight
      this.$store.commit('base/setLogoChangeBase', height)
      this.$root.$emit('onNavStatus')
    },
  },
  methods: {
    afterGetPageData() {
      this.setAnchor(this.anchorList)
    },
  },
}
</script>

<style lang="sass" type="text/sass">
</style>
